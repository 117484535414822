import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {Draggable} from "gsap/Draggable";

const fourColumns = () => {
  gsap.registerPlugin(ScrollTrigger);

  // Pobranie elementów
  const boxes = document.querySelectorAll('.four_columns__box');
  const container = document.querySelector('.four_columns__container');

  // Sprawdzenie, czy elementy istnieją
  if (boxes.length > 0 && container) {
    const offsetY = 60; // Stała wartość przesunięcia

    boxes.forEach((box, index) => {
      const col = index % 4;     // kolumna (0-3)
      const row = Math.floor(index / 4); // rzad (0, 1, 2...)
      gsap.set(box, {y: col * offsetY + row * offsetY});
    });

    gsap.to(boxes, {
      y: 0, // wyrównanie
      scrollTrigger: {
        trigger: container, // Użycie zmiennej container
        start: 'center bottom-=100', // Start animacji, gdy środek kontenera jest 100px powyżej dołu viewportu
        end: 'center center+=200',
        scrub: true,
        toggleActions: 'play none none reverse'
      }
    });
  } else {
    console.warn("Nie znaleziono wymaganych elementów dla animacji fourColumns.");
  }
}

const slideshow = () => {
  gsap.registerPlugin(Draggable);
  const slider = document.querySelector(".slideshow__slider");
  if (slider) {
    const slides = Array.from(slider.children);
    const prevBtn = document.getElementById("prev");
    const nextBtn = document.getElementById("next");

    let slideWidth;
    let currentIndex = 0;

    function updateSlideWidth() {
      slideWidth = 0;
      slides.forEach((slide, index) => {
        const marginRight = index < slides.length - 1 ? 32 : 0; // Margines tylko dla slajdów, które nie są ostatnie
        slideWidth += slide.clientWidth + marginRight + 10; // Dodaj gap (10px)
      });
      gsap.set(slider, {x: -currentIndex * slideWidth});
    }

    updateSlideWidth(); // Inicjalizacja slideWidth

    function moveSlider(direction) {
      currentIndex += direction;

      // Zapętlenie
      if (currentIndex < 0) {
        currentIndex = slides.length - 1;
      } else if (currentIndex >= slides.length) {
        currentIndex = 0;
      }

      // Obliczanie docelowej pozycji X
      let targetX = 0;
      for (let i = 0; i < currentIndex; i++) {
        const marginRight = i < slides.length - 1 ? 32 : 0;
        targetX -= slides[i].clientWidth + marginRight;
      }

      // Animacja GSAP
      gsap.to(slider, {x: targetX, duration: 0.8, ease: "power3.out"});
    }

    nextBtn.addEventListener("click", () => moveSlider(1));
    prevBtn.addEventListener("click", () => moveSlider(-1));

    Draggable.create(slider, {
      type: "x",
      inertia: true,
      onDragEnd: function () {
        let closestIndex = Math.round(gsap.getProperty(slider, "x") / -slideWidth);
        currentIndex = (closestIndex + slides.length) % slides.length;
        gsap.to(slider, {x: -currentIndex * slideWidth, duration: 1, ease: "expo.out"});
      },
    });

// Aktualizacja szerokości slajdu przy zmianie rozmiaru okna
    window.addEventListener("resize", () => {
      updateSlideWidth();
    });
  }
}


window.addEventListener("load", function () {
  if (window.innerWidth > 768) {
    // Pobranie elementu
    const heroVideoContainer = document.querySelector(".hero-video__container");

    // Sprawdzenie, czy element istnieje
    if (heroVideoContainer) {
      gsap.fromTo(heroVideoContainer,
        {bottom: -500},
        {
          bottom: 0,
          duration: 1,
          delay: 1,
          ease: "power2.out"
        }
      );
    } else {
      console.warn("Nie znaleziono elementu .hero-video__container");
    }
  }
});

const videoAnimation = () => {
  gsap.registerPlugin(ScrollTrigger);

  const heroDetails = document.querySelector(".hero-details");
  const heroVideo = document.querySelector(".hero-video video");
  const heroTopText = document.querySelector(".hero-top-text");
  const stickyBanner = document.querySelector(".sticky-banner");

  if (heroDetails && heroVideo && heroTopText && stickyBanner) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: heroDetails,
        start: "top top",
        end: "bottom top",
        pin: true,
        scrub: true,
        markers: false,
      },
    });

    tl.fromTo(
      heroVideo,
      {width: "400px"},
      {width: "calc(100vw - 208px)", height: "calc(100vh - 200px)", ease: "none"},
      0
    );

    tl.fromTo(heroTopText, {y: 0}, {y: -500, ease: "none"}, 0);

    tl.fromTo(stickyBanner, {y: 0}, {y: -300, ease: "none"}, 0);
  } else {
    console.warn("Nie znaleziono wymaganych elementów do animacji wideo.");
  }
};


const gsapScroll = () => {
  gsap.registerPlugin(ScrollTrigger);

  // Pobranie elementów
  const slides = gsap.utils.toArray(".screen-scroll__pages__page");
  const container = document.querySelector(".screen-scroll__pages");

  // Sprawdzenie, czy elementy istnieją
  if (slides.length > 0 && container) {
    slides.forEach((slide, i) => {
      gsap.set(slide, {y: i === 0 ? "0%" : "100%"});
    });

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: container, // Użycie zmiennej container
        pin: true,
        scrub: true,
        start: "top top",
        end: () => "+=" + ((slides.length - 1) * window.innerHeight),
      }
    });

    slides.forEach((slide, i) => {
      if (i !== 0) {
        tl.to(slide, {
          y: 0,
          duration: 1,
          ease: "none"
        }, i);
      }
    });
  } else {
    console.warn("Nie znaleziono wymaganych elementów dla animacji gsapScroll.");
  }
}

document.addEventListener("DOMContentLoaded", function () {
  let elements = document.querySelectorAll('.screen-scroll__pages__page');
  if (elements.length > 0) {
    gsapScroll();
  }
});
document.addEventListener("resize", function () {
  let elements = document.querySelectorAll('.screen-scroll__pages__page');
  if (elements.length > 0) {
    gsapScroll();
  }
});


window.ogHandler = () => {
  document.addEventListener('DOMContentLoaded', function () {
    let video = document.getElementById('video');
    if (video) {
      video.play().catch(function (error) {
        console.error('Autoplay failed:', error);
      });
    }
  });


  const toc = document.getElementsByClassName('article__body__content__toc__li');
  const findId = function () {
    let attribute = this.getAttribute('data-toc');
    attribute = attribute.replace(/\s/g, '');
    window.scroll({
      top: document.querySelector('h2[data-block-key="' + attribute + '"]').offsetTop - 20,
      left: 0,
      behavior: 'smooth',
    });
  };

  for (let i = 0; i < toc.length; i++) {
    toc[i].addEventListener('click', findId, false);
  }


  const tocCase = document.getElementById('case24-toc');
  if (tocCase) {
    window.addEventListener('scroll', () => {
      const scrollY = window.scrollY;
      if (scrollY > 487) {
        tocCase.style.position = 'fixed';
        tocCase.style.top = '0';
      } else {
        tocCase.style.position = 'absolute';
        tocCase.style.top = '488px';
      }
    })
  }

  function smoothScroll() {
    let x = document.querySelectorAll('a[href*="#"]');
    for (let i = 0; i < x.length; i++) {
      x[i].addEventListener('click', function (e) {
        e.preventDefault();
        let target = document.getElementById(this.hash.split('#')[1]);
        target.scrollIntoView({
          behavior: 'smooth', block: 'start', inline: 'start'
        });
      });
    }
  }

  if (window.innerWidth > 768) {
    videoAnimation();
  }
  smoothScroll();
  slideshow();
  fourColumns();


  const services = document.getElementsByClassName('new-services__list__nav__link');
  const showBox = function () {
    this.parentElement.querySelector('.active-color').classList.remove('active-color');

    let title = this.getAttribute('data-title');
    let element = document.getElementById(title);
    element.parentElement.querySelector('.active').classList.remove('active');
    element.classList.add('active');
    this.classList.add('active-color');
  };

  for (let i = 0; i < services.length; i++) {
    services[i].addEventListener('click', showBox, false);
  }

  const showRodo = function () {
    document.querySelector('.show-more-rodo').classList.add('active');
    document.querySelector('.show-more-click').classList.add('hide');
  };

  const show_more_click = document.getElementsByClassName('show-more-click');
  if (show_more_click[0]) {
    show_more_click[0].addEventListener('click', showRodo, false);
  }

  //menu hover

  function opacityContent() {
    let submenu = document.getElementsByClassName('is_open');
    const overlay = document.getElementsByClassName('overlay-dark');
    if (submenu[0]) {
      overlay[0].style.visibility = 'visible'
    } else {
      overlay[0].style.visibility = 'hidden';
    }
  }

  const overlay = document.getElementsByClassName('submenu-overlay');

  function initValueOpenMenu() {
    let elements_submenu = document.getElementsByClassName('nav-submenu');

    for (let i = 0; i < elements_submenu.length; i++) {
      elements_submenu[i].setAttribute("data-height", elements_submenu[i].clientHeight);
      overlay[0].style.height = '0';

      elements_submenu[i].parentNode.classList.remove('is_open');
      elements_submenu[i].style.opacity = '0';
      opacityContent();
    }
  }

  function removeOpen() {
    let elements_submenu = document.getElementsByClassName('nav-submenu');
    for (let i = 0; i < elements_submenu.length; i++) {
      elements_submenu[i].onmouseleave = function () {
        this.parentNode.classList.remove("is_open");
        elements_submenu[i].style.opacity = '0';
        overlay[0].style.height = '0';
        opacityContent();
      }
    }
  }

  initValueOpenMenu();
  removeOpen();
  opacityContent();


  let elements = document.getElementsByClassName('menu__link');
  for (let i = 0; i < elements.length; i++) {
    let is_navsubmenu = elements[i].parentNode.getElementsByClassName('nav-submenu');
    elements[i].onmouseenter = function () {
      const elementOpen = document.querySelectorAll(".is_open");
      if (elementOpen.length > 0) {
        for (let i = 0; i < elementOpen.length; i++) {
          elementOpen[i].classList.remove('is_open');
          overlay[0].style.height = '0';
          opacityContent();
          let submenu = elementOpen[i].querySelector('div.nav-submenu');
          if (submenu.length > 0) {
            submenu[0].style.opacity = '0';
            opacityContent();
          } else {
            initValueOpenMenu();
          }
        }
      }
      if (is_navsubmenu.length > 0) {
        this.parentNode.classList.add("is_open");
        opacityContent();
        let active = document.querySelector('li.is_open div.nav-submenu');
        let heightActive = active.getAttribute('data-height');
        active.style.height = heightActive + 'px';
        active.style.opacity = '1';
        overlay[0].style.height = heightActive + 'px';
        overlay[0].style.transform = "translateY(" + 107 + "px)";
      }
    }
  }


  let title = {
    easing: 'ease-in',
    duration: 800
  };

  let subtitle = {
    easing: 'ease-in',
    duration: 1000
  };

  let paragraph = {
    easing: 'ease-in',
    duration: 1300
  };

  let media = {
    easing: 'ease-in',
    duration: 500
  };

  const animation = () => {


    let h1s = document.querySelectorAll('.details__body h1, .article h1');
    gsap.from(h1s, {
      clipPath: "inset(100% 0 0 0)", // tekst jest „schowany” poniżej
      y: 50,                         // dodatkowe przesunięcie w pionie (od dołu)
      duration: 1,                  // czas trwania animacji w sekundach
      ease: "power2.inOut",
      stagger: 0.2                  // jeśli jest wiele h1, animują się sekwencyjnie
    });


    let h2s = document.querySelectorAll('.details__body h2, .article h2');
    ScrollReveal().reveal(h2s, subtitle);

    let h3s = document.querySelectorAll('.details__body h3, .article h3');
    ScrollReveal().reveal(h3s, subtitle);

    let ps = document.querySelectorAll('.details__body p:not(.no-animate), .article p:not(.no-animate)');
    // let ps_array = [...ps];
    // for(let i=0; i <= ps_array.length; i++){
    //   if(ps_array[i].querySelector('no-animate').contains) {
    //     ps_array.splice(i, ps_array.length);
    //   }
    // }
    ScrollReveal().reveal(ps, paragraph);

    // let as = document.querySelectorAll('.details__body a, .article a');
    // ScrollReveal().reveal(as, paragraph);

    let imgs = document.querySelectorAll('.details__body img,  .article img');
    ScrollReveal().reveal(imgs, media);

    let picture = document.querySelectorAll('.details__body picture,  .article picture');
    ScrollReveal().reveal(picture, media);

    let lis = document.querySelectorAll('.details__body li, .article li');
    ScrollReveal().reveal(lis, {interval: 300, easing: 'ease-in',});

    let videos = document.querySelectorAll('.details__body video:not(.no-animate)');
    ScrollReveal().reveal(videos, media);

    const links = document.querySelectorAll('.navbar__list__element__href');

    links.forEach(link => {
      const underline = link.querySelector('.underline');

      link.addEventListener('mouseenter', () => {
        gsap.to(underline, {
          scaleX: 1,
          duration: 0.3,    // czas trwania animacji (sekundy)
          ease: "power2.out"
        });
      });

      link.addEventListener('mouseleave', () => {
        gsap.to(underline, {
          scaleX: 0,
          duration: 0.3,
          ease: "power2.out"
        });
      });
    });

    document.querySelector('body').style.visibility = 'visible';
  }

  animation();


  const boxes = document.querySelectorAll('.case-study-image');
  boxes.forEach(box => {
    let video = box.querySelector('video');
    let img = box.querySelector('img');
    let height = img.clientHeight;
    box.style.paddingTop = height + 'px';
    if (video) {
      video.style.height = height + 'px';
    }
    if (img) {
      img.style.height = height + 'px';
    }
    if (video) {
      box.addEventListener('mouseenter', () => {
        video.play();
      });
    }
    if (video) {
      box.addEventListener('mouseleave', () => {
        video.pause();
        video.currentTime = 0;
      });
    }
  });


  const togglesTitle = document.querySelectorAll('.toggle__right__list__title');

  togglesTitle.forEach((title) => {
    title.addEventListener('click', () => {
      // 1) Usuwamy "no-after" ze wszystkich tytułów
      togglesTitle.forEach((titleOne) => titleOne.classList.remove('no-after'));
      // 2) Dodajemy "no-after" do klikniętego tytułu
      title.classList.add('no-after');

      // 3) Identyfikujemy element, który ma się stać aktywny
      const newActiveId = title.dataset.id;
      const newActive = document.getElementById(newActiveId);

      // 4) Pobieramy obecnie aktywny element (jeśli taki jest)
      const oldActive = document.querySelector('.toggle__right__list__li--active');

      // -------------------------------------------
      // FUNKCJA CHOWAJĄCA STARY ELEMENT (FADE & HEIGHT)
      // -------------------------------------------
      function hideOldElement(element, callback) {
        gsap.to(element, {
          duration: 0.3,
          autoAlpha: 0, // łączy opacity i visibility
          height: 0,    // zwijamy do 0
          onComplete: () => {
            element.classList.remove('toggle__right__list__li--active');
            // Usuwamy inline style, gdybyśmy chcieli mieć
            // docelowo "auto" w height lub inne wartości
            element.style.removeProperty('height');
            element.style.removeProperty('overflow');
            if (callback) callback();
          },
        });
      }

      // -------------------------------------------
      // FUNKCJA POKAZUJĄCA NOWY ELEMENT (FADE & HEIGHT)
      // -------------------------------------------
      function showNewElement(element) {
        // Dodajemy klasę 'active' żeby był widoczny w HTML
        element.classList.add('toggle__right__list__li--active');

        // Ustawiamy tymczasowo overflow i height: 0, by animacja była płynna
        element.style.overflow = 'hidden';
        element.style.height = '0px';
        // Zanim zaczniemy animować height, odczytujemy scrollHeight
        const targetHeight = element.scrollHeight;

        gsap.to(element, {
          duration: 0.4,
          autoAlpha: 1,
          height: targetHeight,
          onComplete: () => {
            // Po zakończeniu animacji przywracamy wartości
            element.style.removeProperty('height');
            element.style.removeProperty('overflow');
          },
        });
      }

      // -------------------------------------------
      // GŁÓWNA LOGIKA KLIKNIĘCIA
      // -------------------------------------------
      // Jeżeli istnieje stary element i jest on inny niż kliknięty
      if (oldActive && oldActive !== newActive) {
        hideOldElement(oldActive, () => {
          // Po schowaniu starego elementu – pokazujemy nowy
          showNewElement(newActive);
        });
      }
      // Jeżeli nie ma żadnego aktywnego elementu (pierwsze kliknięcie)
      else if (!oldActive) {
        showNewElement(newActive);
      }
      // Jeżeli kliknięto w ten sam element, który jest już aktywny,
      // możesz dodać logikę zamykania / ignorowania / etc.
    });
  });
}
/* TODO: work in progress */
/*document.addEventListener('DOMContentLoaded', function () {
    const sections = document.querySelectorAll('.section-to-active');
    const links = document.querySelectorAll('.link-to-active');

    const observerOptions = {
      threshold: [0, 1]};

    const observer = new IntersectionObserver((entries) => {
      entries.every(entry => {
        const targetLink = document.querySelector(`.link-to-active[href="#${entry.target.id}"]`);

        if (entry.isIntersecting) {
          console.log('jaki jest widoczny:', entry.target.id);
          targetLink.classList.add('active');
        } else {
          console.log('uciekamy stąd:', entry.target.id);
          targetLink.classList.remove('active');
        }
      });
    }, observerOptions);

    sections.forEach(section => {
      observer.observe(section);
    });
  });*/
